import { Link } from "@StarberryUtils";
import React from "react";
//import ScrollAnimation from 'react-animate-on-scroll';
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import  "./Breadcrumb.scss";
//import "animate.css/animate.min.css";


const StaticBreadcrumbs =(props) => {

    return(
  <div className={`breadcrumb-wrp ${props.tag !== 'service-landing-banner' ? 'text-center' : ''} ${props.tag === 'market-property' ? 'market-property-breadcrumb' : ''} ${props.tag === 'contact-landing' ? 'contact-landing-breadcrumb' : ''} components--MgServices--Breadcrumb--Breadcrumb`} >

    <Breadcrumb>
    <Breadcrumb.Item className="text-uppercase"><Link to="/">home</Link></Breadcrumb.Item>

    {props.parent ? <Breadcrumb.Item className="text-uppercase"><Link to="../../">{props.parent}</Link></Breadcrumb.Item> : ''}

    {props.contact_parent ? <Breadcrumb.Item className="text-uppercase"><Link to="../">{props.contact_parent}</Link></Breadcrumb.Item> : ''}

    {props.page ? <Breadcrumb.Item className="text-uppercase" active>{props.page}</Breadcrumb.Item> : ''}
    </Breadcrumb>
  </div>
)
    }

export default StaticBreadcrumbs;

